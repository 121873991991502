/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Navigation from "./navigation"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 1200,
          padding: `0 1.0875rem 1.45rem`,
          display: `flex`
        }}
      >
        <Navigation />
        <main style={{
          width: `60%`,
          position: `relative`,
          display: `flex`,
          flexDirection: 'column'
        }}>{children}</main>
        <div 
          id="articleContents"
          style={{
            width: `20%`
          }}>
        </div>
      </div>
      <footer style={{
        marginTop: `2rem`,
        backgroundColor: `#EDEDED`
      }}>
        <div style={{
          margin: `0 auto`,
          maxWidth: 1200
        }}>
          © {new Date().getFullYear()}, Built with
          {` `}
          <a href="https://www.gatsbyjs.com">Gatsby</a>
        </div>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
