import PropTypes from "prop-types"
import React from "react"

import { useStaticQuery, graphql, Link } from "gatsby"

const Navigation = ({data}) => {
  const nav = useStaticQuery(graphql`
    query NavQuery {
      allMarkdownRemark(limit: 10
      sort: {fields: [frontmatter___chapter, frontmatter___sub_chapter], order: DESC}
    ) {
      edges {
        node {
          id
          frontmatter {
            path
            chapter
            title
            date
            sub_chapter
          }
        }
      }
    }
  }
  `)

  var {currentChapter, currentSubChapter} = '';

  return (
    <div id="sideNav" style={{
      width: `20%`,
      padding: `0 0 1.45rem 0`,
    }}>
      <ul style={{
        listStyleType: `none`,
        position: `sticky`,
        paddingTop: 20,
        top: 0,
        fontSize: 14
      }}>
        {nav.allMarkdownRemark.edges.map(function(post, index) {
          if(currentChapter !== post.node.frontmatter.chapter) {
            currentChapter = post.node.frontmatter.chapter;
            if(currentSubChapter !== post.node.frontmatter.chapter+post.node.frontmatter.sub_chapter) {
              currentSubChapter = post.node.frontmatter.chapter+post.node.frontmatter.sub_chapter;
              return (
                <>
                  <li className="Chapter">{post.node.frontmatter.chapter}</li>
                  <li className="SubChapter">{post.node.frontmatter.sub_chapter}</li>
                  <li><Link to={post.node.frontmatter.path}>{post.node.frontmatter.title}</Link></li>
                </>
              )
            }else{
              currentSubChapter = post.node.frontmatter.chapter+post.node.frontmatter.sub_chapter;
              return (
                <>
                  <li className="Chapter">{post.node.frontmatter.chapter}</li>
                  <li><Link to={post.node.frontmatter.path}>{post.node.frontmatter.title}</Link></li>
                </>
              )
            }
          }else{
            currentChapter = post.node.frontmatter.chapter;
            if(currentSubChapter !== post.node.frontmatter.chapter+post.node.frontmatter.sub_chapter) {
              currentSubChapter = post.node.frontmatter.chapter+post.node.frontmatter.sub_chapter;
              return(
                <>
                  <li className="SubChapter">{post.node.frontmatter.sub_chapter}</li>
                  <li><Link to={post.node.frontmatter.path}>{post.node.frontmatter.title}</Link></li>
                </>
              )
            }else{
              currentSubChapter = post.node.frontmatter.chapter+post.node.frontmatter.sub_chapter;
              return(
                <li><Link to={post.node.frontmatter.path}>{post.node.frontmatter.title}</Link></li>
              )
            }

          }
        })}
      </ul>
    </div>
  )
}

export default Navigation
